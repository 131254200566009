import type { useReward } from "react-rewards"
import colors from "tailwindcss/colors"

export type UseRewardParams = Parameters<typeof useReward>

const _taskCompleted: {
  id: UseRewardParams[0]
  type: UseRewardParams[1]
  config: UseRewardParams[2]
} = {
  id: "rewardTaskCompleted",
  type: "emoji",
  config: {
    emoji: ["✅", "👍"],
    startVelocity: 15,
    elementSize: 16,
    elementCount: 35,
    spread: 300,
    decay: 0.97,
    lifetime: 75,
  },
}

const _incrementReaction: (emoji: string) => {
  id: UseRewardParams[0]
  type: UseRewardParams[1]
  config: UseRewardParams[2]
} = (emoji) => ({
  id: "incrementReaction",
  type: "emoji",
  config: {
    emoji: [emoji],
    startVelocity: 13,
    elementSize: 16,
    elementCount: 10,
    spread: 200,
    decay: 0.97,
    lifetime: 75,
  },
})

const _addReaction: {
  id: UseRewardParams[0]
  type: UseRewardParams[1]
  config: UseRewardParams[2]
} = {
  id: "addReaction",
  type: "confetti",
  config: {
    startVelocity: 13,
    elementSize: 8,
    elementCount: 25,
    spread: 200,
    decay: 0.97,
    lifetime: 150,
    colors: [
      colors.indigo[500],
      colors.blue[500],
      colors.cyan[500],
      colors.teal[500],
      colors.emerald[500],
      colors.yellow[500],
    ],
  },
}

const _bigConfetti: {
  id: UseRewardParams[0]
  type: UseRewardParams[1]
  config: UseRewardParams[2]
} = {
  id: "rewardCaseClosed",
  type: "confetti",
  config: {
    lifetime: 750,
    spread: 360,
    elementCount: 150,
    decay: 0.97,
    colors: [
      colors.indigo[500],
      colors.blue[500],
      colors.cyan[500],
      colors.teal[500],
      colors.emerald[500],
      colors.yellow[500],
    ],
  },
}

export const preset = {
  taskCompleted: {
    ..._taskCompleted,
    params: Object.values(_taskCompleted) as UseRewardParams,
  },
  caseClosed: {
    ..._bigConfetti,
    params: Object.values(_bigConfetti) as UseRewardParams,
  },
  poaSuccess: {
    ..._bigConfetti,
    params: Object.values(_bigConfetti) as UseRewardParams,
  },
  addReaction: {
    ..._addReaction,
    params: Object.values(_addReaction) as UseRewardParams,
  },
  incrementReaction: (emoji: string) => {
    const config = _incrementReaction(emoji)
    return {
      ...config,
      params: Object.values(config) as UseRewardParams,
    }
  },
} as const
