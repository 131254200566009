import { Chip, type ChipProps } from "@/components/ui/data-display/Chip"
import type { Nullable } from "@/global"
import type { documents } from "dcp-types"
import type { HTMLAttributes } from "react"
import useDocumentCategoryLabel from "../../_hooks/useDocumentCategoryLabel"
import { getCategoryChipColor } from "../../_utils/categoryStyles"
import DocumentIcon from "../DocumentIcon"
import { Typography } from "@/components/ui/data-display/Typography"

interface DocumentChipProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "color"> {
  document?: Nullable<Pick<documents, "category">>
  size?: "tiny" | "small" | "medium"
  onDelete?: ChipProps["onDelete"]
  onClick?: ChipProps["onClick"]
  strikeThrough?: boolean
}

const _getIconSize = (size?: DocumentChipProps["size"]) => {
  switch (size) {
    case "tiny":
      return 0.625
    case "small":
      return 0.75
    case "medium":
      return 1
    default:
      return 1
  }
}

export default function DocumentChip({
  document,
  size = "small",
  onDelete,
  onClick,
  strikeThrough,
  ...attributes
}: DocumentChipProps) {
  const { getDocumentCategoryLabel } = useDocumentCategoryLabel()

  const chipSize = size !== "tiny" ? size : "small"
  return (
    <Chip
      {...attributes}
      className={`${size === "tiny" ? "h-5" : ""} ${
        strikeThrough ? "opacity-40" : ""
      } ${attributes.className}`}
      variant="outlined"
      color={getCategoryChipColor(document?.category)}
      avatar={
        <DocumentIcon category={document?.category} size={_getIconSize(size)} />
      }
      label={
        <Typography
          variant="body2"
          color={document?.category ? "inherit" : "text.disabled"}
          className={strikeThrough ? "line-through" : ""}
        >
          {getDocumentCategoryLabel(document?.category)}
        </Typography>
      }
      size={chipSize}
      onDelete={onDelete}
      onClick={onClick}
    />
  )
}
