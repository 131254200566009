"use client"

import DocumentChip from "@/app/[locale]/(document)/_components/DocumentChip"
import FileItem from "@/app/_components/FileItem"
import { Typography } from "@/components/ui/data-display/Typography"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import { Card } from "@/components/ui/surfaces/Card"
import { CardContent } from "@/components/ui/surfaces/CardContent"
import { Collapse } from "@/components/ui/utils/Collapse"
import { getDayjsTimeFormat } from "@/utils/format"
import { mdiChevronDown, mdiChevronUp, mdiClose, mdiEye } from "@mdi/js"
import Icon from "@mdi/react"
import dayjs from "dayjs"

import { useState, type ReactNode } from "react"

import useAside from "@/app/_components/Aside/hooks/useAside"
import type { TAttachment } from "../Message"

export interface MessageAttachmentProps {
  attachment?: TAttachment | null
  createdAt?: string
  onDelete?: (attachmentId: string) => void
  toolbarSlot?: ReactNode
}

export default function MessageAttachment({
  attachment,
  createdAt,
  onDelete,
  toolbarSlot,
}: MessageAttachmentProps) {
  const { openAside } = useAside()

  const [collapse, setCollapse] = useState(true)

  const deletable = !!onDelete

  const handleOpenAttachment = () => {
    openAside("document", attachment?.id)
  }

  const handleDeleteAttachment = () => {
    if (deletable && attachment?.id) {
      onDelete(attachment.id)
    }
  }

  return (
    <Card className="rounded-lg" variant="outlined">
      <CardContent className="flex flex-col items-end py-2 px-3 gap-1">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <DocumentChip document={attachment} />
          <IconButton size="small" onClick={handleOpenAttachment}>
            <Icon path={mdiEye} size={1} />
          </IconButton>
          <Box minWidth={16} flexGrow={1} />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <IconButton
              size="small"
              onClick={setCollapse.bind(null, !collapse)}
            >
              <Icon path={collapse ? mdiChevronDown : mdiChevronUp} size={1} />
            </IconButton>
            {deletable && (
              <IconButton size="small" onClick={handleDeleteAttachment}>
                <Icon path={mdiClose} size={1} />
              </IconButton>
            )}
            {toolbarSlot}
          </Stack>
        </Stack>
        <Collapse className="w-full" in={!collapse}>
          <div>
            <FileItem
              documentId={attachment?.id}
              generated={false}
              type={attachment?.mime_type}
              name={attachment?.name ?? ""}
              version={attachment?.storage_version}
              size="small"
              byteSize={attachment?.size}
              width="100%"
            />
          </div>
        </Collapse>
        {createdAt && (
          <Typography variant="caption" color="text.disabled">
            {dayjs(createdAt).format(getDayjsTimeFormat())}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
