import { useCallback, useEffect } from "react"

export function useKeydown(
  keyCode: string,
  callback: () => void,
): {
  add: () => void
  remove: () => void
} {
  const listener = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === keyCode) {
        callback()
      }
    },
    [callback, keyCode],
  )
  const add = useCallback(
    () => document.addEventListener("keydown", listener),
    [listener],
  )
  const remove = useCallback(
    () => document.removeEventListener("keydown", listener),
    [listener],
  )
  useEffect(() => {
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [listener])

  return { add, remove }
}
