"use client"

import { AuthorizationHeaderKey } from "./shared"

export async function downloadDocument({
  lawFirmId,
  documentId,
  accessToken,
  version,
}: {
  lawFirmId: string
  documentId: string
  version: number
  accessToken?: string
}) {
  const response = await fetch(
    `/api/v1/document/${lawFirmId}/data/${documentId}?version=${version}`,
    {
      headers: accessToken
        ? {
            [AuthorizationHeaderKey]: accessToken,
          }
        : {},
    },
  )

  if (!response.ok) {
    throw new Error(await response.text())
  }

  return response.blob()
}
