import type { MenuProps } from "@mui/material"
import { useState, type MouseEvent } from "react"

export default function useMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const closeMenu = () => {
    setAnchorEl(null)
  }
  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return {
    menuProps: {
      anchorEl,
      open,
      onClose: closeMenu,
    } as Pick<MenuProps, "anchorEl" | "open" | "onClose">,
    closeMenu,
    openMenu,
  }
}
