"use client"

import { ListItemIcon } from "@/components/ui/data-display/ListItemIcon"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Typography } from "@/components/ui/data-display/Typography"
import { LinearProgress } from "@/components/ui/feedback/LinearProgress"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { Stack } from "@/components/ui/layout/Stack"
import { Menu } from "@/components/ui/navigation/Menu"
import useMenu from "@/components/ui/navigation/Menu/useMenu"
import { MenuItem } from "@/components/ui/navigation/MenuItem"
import { useTranslation } from "@/i18n"
import { humanFileSize } from "@/utils/format"
import {
  mdiClose,
  mdiDotsVertical,
  mdiFileUploadOutline,
  mdiTrayArrowDown,
} from "@mdi/js"
import Icon from "@mdi/react"
import { useTheme } from "@mui/material/styles"

import { downloadDocument } from "@/app/api/v1/document/[lawFirmId]/data/[documentId]/action"
import { downloadBlob } from "@/utils/data"
import { useGetUserSessionData } from "@/utils/lib"
import type { StackProps } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useState, type HTMLAttributes } from "react"
import { FileIcon } from "../FileIcon"
import de from "./messages/de.json"

export type FileItemStatus = "uploading" | "uploaded" | "error"

export interface FileItemProps
  extends Omit<
      HTMLAttributes<HTMLDivElement>,
      "color" | "children" | "onChange"
    >,
    StackProps {
  documentId?: string
  version?: number
  generated?: boolean
  status?: FileItemStatus
  size?: "small" | "medium"
  type?: string
  name?: string
  byteSize?: number
  statusIcon?: string
  statusLabel?: string
  onDelete?: () => void
}

export default function FileItem({
  documentId,
  generated,
  status = "uploaded",
  size,
  type,
  name,
  version,
  byteSize,
  statusIcon,
  statusLabel,
  onDelete,
  ...attributes
}: FileItemProps) {
  const { t } = useTranslation(de)
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const userSessionDataQuery = useGetUserSessionData()
  const lawFirmId = userSessionDataQuery.data?.data?.organizationId

  const { menuProps, closeMenu, openMenu } = useMenu()

  const fileNameExtension = name?.split(".")?.reverse()?.at(0)

  const nameColor = status === "error" ? "error.main" : "text.primary"
  const labelColor = status === "error" ? "error.main" : "text.secondary"

  const nameVariant = size === "small" ? "caption" : "body1"
  const labelVariant = size === "small" ? "captionTiny" : "body2"

  const hasDownloadOption = lawFirmId && documentId
  const hasMultipleMenuOptions = false

  const [isLoading, setIsLoading] = useState(false)
  const handleDownloadAttachment = useCallback(async () => {
    closeMenu()

    if (!lawFirmId || !documentId) {
      return
    }

    setIsLoading(true)

    const blob = await downloadDocument({
      lawFirmId: lawFirmId,
      documentId: documentId,
      version: version ?? 0,
    }).catch(() => null)

    if (!blob) {
      enqueueSnackbar(t("download.error.unknown"), {
        variant: "error",
      })
      return
    }

    downloadBlob(blob, name ?? t("download.unknownFilename"))
    setIsLoading(false)
  }, [lawFirmId, documentId, closeMenu, name, enqueueSnackbar, t, version])

  const handleDeleteAttachment = useCallback(() => {
    if (onDelete) {
      onDelete()
    }
  }, [onDelete])

  return (
    <Stack direction="row" spacing={0.5} alignItems="start" {...attributes}>
      {status === "uploading" && (
        <Icon
          path={mdiFileUploadOutline}
          size={1}
          color={theme.palette.primary.main}
        />
      )}
      {status === "error" && (
        <Icon
          path={mdiFileUploadOutline}
          size={1}
          color={theme.palette.error.main}
        />
      )}
      {status === "uploaded" && (
        <FileIcon mimeType={type} fileNameExtension={fileNameExtension} />
      )}
      <Stack flexGrow={1}>
        <Typography variant={nameVariant} color={nameColor}>
          {name}
        </Typography>
        <Stack direction="row" spacing={0.5} alignItems="start" pb={0.5}>
          <Typography variant={labelVariant} color={labelColor}>
            {!byteSize && byteSize !== 0
              ? t("unknownByteSize")
              : humanFileSize(byteSize)}
          </Typography>
          {statusLabel && (
            <Typography variant={labelVariant} color={labelColor}>
              •
            </Typography>
          )}
          {statusLabel && (
            <Typography variant={labelVariant} color={labelColor}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                {statusIcon && (
                  <Icon className="fill-current" path={statusIcon} size={0.5} />
                )}
                <span>{statusLabel}</span>
              </Stack>
            </Typography>
          )}
        </Stack>
        {status === "uploading" && <LinearProgress />}
      </Stack>
      {status === "uploaded" &&
        !hasMultipleMenuOptions &&
        hasDownloadOption && (
          <IconButton
            data-testid="file-item-download"
            className="shrink-0"
            size="small"
            onClick={handleDownloadAttachment}
            loading={isLoading}
          >
            <Icon path={mdiTrayArrowDown} size={1} />
          </IconButton>
        )}
      {status === "uploaded" && hasMultipleMenuOptions && (
        <IconButton
          data-testid="file-item-options"
          className="shrink-0"
          size="small"
          onClick={openMenu}
        >
          <Icon path={mdiDotsVertical} size={1} />
        </IconButton>
      )}
      <Menu
        {...menuProps}
        MenuListProps={{
          dense: true,
        }}
      >
        <MenuItem
          data-testid="file-item-menu-option-downlaod"
          onClick={handleDownloadAttachment}
        >
          <ListItemIcon>
            <Icon path={mdiTrayArrowDown} size={0.75} />
          </ListItemIcon>
          <ListItemText>{t("download.label")}</ListItemText>
        </MenuItem>
      </Menu>
      {status !== "uploading" && onDelete && (
        <IconButton
          data-testid="file-item-option-delete"
          className="shrink-0"
          size="small"
          onClick={handleDeleteAttachment}
        >
          <Icon path={mdiClose} size={1} />
        </IconButton>
      )}
    </Stack>
  )
}
