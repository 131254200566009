"use client"

import { Stack } from "@/components/ui/layout/Stack"
import usePopper from "@/components/ui/utils/Popper/usePopper"
import { formatPersonName } from "@/utils/format"
import { type UseRewardParams, preset } from "@/utils/reward"
import { mdiEmoticonPlusOutline } from "@mdi/js"
import Icon from "@mdi/react"
import { useTheme } from "@mui/material/styles"
import { alphabetical, group } from "radash"
import { type HTMLAttributes, forwardRef, useMemo } from "react"
import { useReward } from "react-rewards"
import ReactionCounterGroup from "../ReactionCounterGroup"
import ReactionSelectPopper from "../ReactionSelectPopper"
import { Grow } from "@/components/ui/utils/Grow"
import type { chat_message_reactions, persons } from "dcp-types"
import type { Selectable } from "kysely"

export interface ReactionCounterProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onSelect"> {
  messageId?: string
  userId?: string
  counters?: (Pick<
    Selectable<chat_message_reactions>,
    "id" | "reaction" | "sender_person_id"
  > & {
    sender_person: Pick<
      Selectable<persons>,
      "first_name" | "last_name" | "title"
    > | null
  })[]
  onSelect?: (emoji: string) => void
  onAdd?: (emoji: string) => void
  emojis?: string[]
}

const ReactionCounter = forwardRef<HTMLDivElement, ReactionCounterProps>(
  (
    {
      messageId,
      userId,
      counters = [],
      onSelect = () => {},
      onAdd = () => {},
      emojis,
      ...attr
    }: ReactionCounterProps,
    ref,
  ) => {
    const theme = useTheme()
    const rewardConfig = useMemo(() => {
      const uuid = messageId ?? Math.random().toString(36).substring(7)

      return {
        ...preset.addReaction,
        id: preset.addReaction.id + uuid,
        params: [
          preset.addReaction.id + uuid,
          ...preset.addReaction.params.slice(1),
        ] as UseRewardParams,
      }
    }, [messageId])
    const { reward } = useReward(...rewardConfig.params)

    const groups = useMemo(
      () => group(counters, (count) => count.reaction),
      [counters],
    )
    const reactionEmojis = useMemo(
      () => alphabetical(Object.keys(groups), (e) => e),
      [groups],
    )

    const { popperProps, openPopper, closePopper } = usePopper()

    const availableEmojis = useMemo(
      () =>
        alphabetical(
          emojis?.filter((emoji) => {
            const g = groups
            return !g[emoji]
          }) || [],
          (e) => e,
        ),
      [emojis, groups],
    )

    return (
      <Stack
        ref={ref}
        direction="row"
        spacing={0.5}
        alignItems="center"
        {...attr}
      >
        <Stack direction="row" spacing={0.75} alignItems="center">
          {reactionEmojis.map((emoji: string) => {
            const names =
              groups[emoji]?.map(({ sender_person }) => {
                return formatPersonName(sender_person)
              }) ?? []
            const isActive = groups[emoji]?.some(
              ({ sender_person_id }) => userId === sender_person_id,
            )

            return (
              <Grow key={emoji} in={true}>
                <div>
                  <ReactionCounterGroup
                    data-testid={`message-reaction-counter-${emoji}`}
                    messageId={messageId}
                    emoji={emoji}
                    count={names.length}
                    names={names}
                    active={isActive}
                    onSelect={onSelect}
                  />
                </div>
              </Grow>
            )
          })}
        </Stack>
        <span className="h-0 w-0" id={rewardConfig.id} />
        {availableEmojis?.length > 0 && (
          <div
            data-testid="message-reaction-add-button"
            className="cursor-pointer opacity-50 leading-[0px] transition-opacity ease-in-out hover:opacity-100"
            onClick={openPopper}
            onKeyDown={openPopper}
          >
            <Icon
              path={mdiEmoticonPlusOutline}
              size={0.833}
              color={theme.palette.grey[500]}
            />
          </div>
        )}
        <ReactionSelectPopper
          {...popperProps}
          placement="bottom-end"
          emojis={availableEmojis}
          onSelect={(emoji) => {
            onAdd(emoji)
            reward()
            closePopper()
          }}
          onClose={closePopper}
        />
      </Stack>
    )
  },
)
ReactionCounter.displayName = "ReactionCounter"

export default ReactionCounter
