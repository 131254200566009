"use client"

import type { FC } from "react"
import {
  LinearProgress as MuiLinearProgress,
  type LinearProgressProps,
} from "@mui/material"

export const LinearProgress: FC<LinearProgressProps> = (props) => (
  <MuiLinearProgress {...props} />
)
