"use client"
import { type MutableRefObject, type ReactNode, useEffect } from "react"

export interface ClickAwayOffset {
  top?: number
  right?: number
  bottom?: number
  left?: number
}
export interface CustomClickAwayProps {
  children: ReactNode
  childRef?: MutableRefObject<HTMLElement | undefined | null>
  addOffset?: ClickAwayOffset
  label?: string
  onClickAway: () => void
}
// TODO: listen to keypress and trigger onClickAwaway when esc is clicked
export default function CustomClickAwayListener({
  children,
  childRef,
  addOffset,
  onClickAway = () => {},
}: CustomClickAwayProps) {
  useEffect(() => {
    const clickListener = (evt: MouseEvent) => {
      if (!childRef?.current) {
        return
      }
      const dimensions = childRef.current.getBoundingClientRect()
      const clickIsOutsideOfCustomClickAwayContent =
        evt?.clientX < dimensions.left + (addOffset?.left ?? 0) ||
        evt?.clientX > dimensions.right + (addOffset?.right ?? 0) ||
        evt?.clientY > dimensions.bottom + (addOffset?.bottom ?? 0) ||
        evt?.clientY < dimensions.top + (addOffset?.top ?? 0)
      if (clickIsOutsideOfCustomClickAwayContent) {
        onClickAway()
      }
    }
    document?.addEventListener("click", clickListener)
    return () => {
      document?.removeEventListener("click", clickListener)
    }
  }, [addOffset, childRef, onClickAway])

  return <>{children}</>
}
