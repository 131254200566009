"use client"

import { Button } from "@/components/ui/inputs/Button"
import { Box } from "@/components/ui/layout/Box"
import { useTranslation } from "@/i18n"
import { mdiCreation } from "@mdi/js"
import { Icon } from "@mdi/react"

import FormRow from "@/app/_components/FormRow"
import { trpcAsync } from "@/app/_trpc/client"
import { Divider } from "@/components/ui/data-display/Divider"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { TextField } from "@/components/ui/inputs/TextField"
import { Menu } from "@/components/ui/navigation/Menu"
import useMenu from "@/components/ui/navigation/Menu/useMenu"
import { Toolbar } from "@/components/ui/surfaces/Toolbar"
import type { ButtonProps, TextFieldProps } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useState } from "react"
import de from "../../_messages/de.json"
import { Tooltip } from "@/components/ui/data-display/Tooltip"

export interface AiMessageButtonProps<T = string> extends ButtonProps {
  iconButton?: boolean
  caseId: string
  replyToMessageId?: string
  recipientParticipantId?: string
  label?: string
  customBackendCall?: (inputValue: string) => Promise<T>
  onGenerate?: (result?: T) => void
  textFieldProps?: TextFieldProps // Optional props to pass to the TextField
}

export const AiMessageButton: React.FC<AiMessageButtonProps> = ({
  iconButton,
  caseId,
  replyToMessageId,
  recipientParticipantId,
  label,
  onGenerate,
  customBackendCall,
  textFieldProps = {},
  ...buttonProps
}) => {
  const { t } = useTranslation(de)
  const { enqueueSnackbar } = useSnackbar()
  const [inputValue, setInputValue] = useState("")
  const [loading, setLoading] = useState(false)

  const { openMenu, closeMenu, menuProps } = useMenu()

  const validPrompt = inputValue.trim().length > 0

  const generateEmailDraft = useCallback(
    async (prompt: string) => {
      const draft = await trpcAsync.ai.draft.getForEmail.query({
        replyToMessageId,
        prompt: prompt,
        caseId,
        recipientParticipantId,
      })
      return draft.data
    },
    [caseId, replyToMessageId, recipientParticipantId],
  )

  const handleGenerate = useCallback(async () => {
    setLoading(true)
    try {
      const result = customBackendCall
        ? await customBackendCall?.(inputValue)
        : await generateEmailDraft(inputValue)

      const formattedResult = result.replaceAll("\n\n", "<p></p>")

      onGenerate?.(formattedResult)
    } catch (error) {
      enqueueSnackbar(t("message.generateMessageByAi.error"), {
        variant: "error",
      })
    } finally {
      setLoading(false)
      setInputValue("")
      closeMenu()
    }
  }, [
    customBackendCall,
    generateEmailDraft,
    inputValue,
    onGenerate,
    closeMenu,
    enqueueSnackbar,
    t,
  ])

  return (
    <>
      {iconButton && (
        <Tooltip title={t("message.generateMessageByAi.action")}>
          <IconButton
            size="small"
            color="inherit"
            sx={{ color: "action.active" }}
            {...buttonProps}
            onClick={openMenu}
          >
            <Icon path={mdiCreation} size={1} />
          </IconButton>
        </Tooltip>
      )}
      {!iconButton && (
        <Button
          startIcon={<Icon path={mdiCreation} size={0.75} />}
          size="small"
          color="inherit"
          sx={{ color: "action.active" }}
          {...buttonProps}
          onClick={openMenu}
        >
          {label ?? t("message.generateMessageByAi.action")}
        </Button>
      )}
      <Menu
        className="pb-0"
        MenuListProps={{
          className: "pb-0",
        }}
        {...menuProps}
      >
        <FormRow className="p-4" mainRowProps={{ spacing: 2 }}>
          <TextField
            label={t("message.generateMessageByAi.promptInput")}
            value={inputValue}
            fullWidth
            onChange={(e) => setInputValue(e.target.value)}
            variant="outlined"
            size="small"
            disabled={loading}
            {...textFieldProps}
          />
        </FormRow>
        <Divider />
        <Toolbar className="gap-2" variant="dense">
          <Box flexGrow={1} />
          <Button
            onClick={closeMenu}
            size="small"
            color="inherit"
            sx={{ color: "action.active" }}
            {...buttonProps}
          >
            {label ?? t("message.generateMessageByAi.cancel")}
          </Button>
          <Button
            onClick={handleGenerate}
            size="small"
            loading={loading}
            disabled={!validPrompt}
            color="primary"
            startIcon={<Icon path={mdiCreation} size={0.75} />}
            {...buttonProps}
          >
            {label ?? t("message.generateMessageByAi.action")}
          </Button>
        </Toolbar>
      </Menu>
    </>
  )
}
