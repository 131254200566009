"use client"

import OrganizationChip from "@/app/[locale]/(organization)/_components/OrganizationChip"
import PersonChip from "@/app/[locale]/(person)/_components/PersonChip"
import { Typography } from "@/components/ui/data-display/Typography"
import { Stack } from "@/components/ui/layout/Stack"
import type { SchemaPick } from "@/global"
import { useTranslation } from "@/i18n"
import { getDayjsDateTimeFormat, getDayjsTimeFormat } from "@/utils/format"
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiCreation,
  mdiDelete,
  mdiDotsVertical,
  mdiEye,
  mdiPencil,
  mdiTimerSand,
} from "@mdi/js"
import Icon from "@mdi/react"
import { alpha, useTheme } from "@mui/material/styles"
import dayjs from "dayjs"
import type {
  chat_message_reactions,
  documents,
  email_status,
  organizations,
  persons,
} from "dcp-types"
import ReactionCounter from "../ReactionCounter"

import SystemChip from "@/app/[locale]/(organization)/_components/SystemChip"
import useAside from "@/app/_components/Aside/hooks/useAside"
import { trpc } from "@/app/_trpc/client"
import { Badge } from "@/components/ui/data-display/Badge"
import { ListItemIcon } from "@/components/ui/data-display/ListItemIcon"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Tooltip } from "@/components/ui/data-display/Tooltip"
import { Button } from "@/components/ui/inputs/Button"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { Menu } from "@/components/ui/navigation/Menu"
import useMenu from "@/components/ui/navigation/Menu/useMenu"
import { MenuItem } from "@/components/ui/navigation/MenuItem"
import { Collapse } from "@/components/ui/utils/Collapse"
import { DebounceDelay } from "@/utils/constants"
import {
  useGetUserSessionData,
  type AwaitedReturn,
  type getUserSessionData,
} from "@/utils/lib"
import { useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { useDebounce, useHover } from "@uidotdev/usehooks"
import dynamic from "next/dynamic"
import { useSnackbar } from "notistack"
import { useCallback, useMemo, useState, type HTMLAttributes } from "react"
import de from "../../_messages/de.json"
import MessageAttachment from "../MessageAttachment"
import { AiMessageButton } from "../AiMessageButton"
import type { inferRouterOutputs } from "@trpc/server"
import type { EmailRouter } from "@/server/routers/messages/routers/emails"
import type { ChatRouter } from "@/server/routers/messages/routers/chat"

const EmailPlainTextBody = dynamic(() => import("../EmailPlainTextBody"), {
  ssr: false,
})

const SUPPORTED_EMOJIS = ["👍", "❤️", "🎉", "✅", "😊"]

export const CHAT_CONTENT_PREVIEW_LENGTH = 500

type EmailMessage = inferRouterOutputs<EmailRouter>["get"]["results"][number]
type ChatMessage = inferRouterOutputs<ChatRouter>["get"]["results"][number]

function isEmailMessage(
  message: EmailMessage | ChatMessage,
): message is EmailMessage {
  return "body" in message
}
function isChatMessage(
  message: EmailMessage | ChatMessage,
): message is ChatMessage {
  return "content" in message
}

export type TAttachment = SchemaPick<
  documents,
  "id" | "name" | "size" | "mime_type" | "category" | "storage_version",
  false
>

export interface MessageProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "content"> {
  caseId?: string
  message: EmailMessage | ChatMessage
  typing?: boolean
  readonly?: boolean
  onEdit?: (messageId: string) => void
  onReply?: (messageId: string, text: string) => void
  initialData?: {
    userData?: Awaited<ReturnType<typeof getUserSessionData>>
  }
  // only relevant for ai generated messages:
  recipientParticipantId?: string
}

export default function Message({
  caseId,
  message,
  typing,
  readonly,
  onEdit,
  onReply,
  initialData,
  recipientParticipantId,
  ...attributes
}: MessageProps) {
  const {
    id: messageId,
    is_outgoing: outgoing,
    created_at: createdAt,
    sender_person: creatorPerson,
    sender_organization: creatorOrganization,
    notifications,
  } = message
  const unread = Boolean(notifications?.length)

  const {
    id: emailId,
    subject,
    body,
    status,
    document_attachments: documentAttachments,
  } = isEmailMessage(message)
    ? message
    : {
        id: null,
        subject: null,
        body: null,
        status: null,
        document_attachments: null,
      }

  const {
    content: chatContent,
    reactions,
    document,
  } = isChatMessage(message)
    ? message
    : { content: null, reactions: null, document: null }
  const attachments = documentAttachments ?? (document ? [document] : [])

  const content = body || chatContent

  const { t } = useTranslation(de)
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const { openAside } = useAside()

  const [ref, hover] = useHover<HTMLDivElement>()
  const debouncedHovering = useDebounce(
    hover,
    DebounceDelay.HoverElementEndDelay,
  )
  const hovering = hover || debouncedHovering

  const isCreatedBySystem = useMemo(
    () => outgoing && !creatorPerson,
    [outgoing, creatorPerson],
  )

  const isTextContentOverflowing = useMemo(
    () =>
      Boolean(
        content?.length &&
          (content.length > CHAT_CONTENT_PREVIEW_LENGTH ||
            content.split("<br>").length > 2),
      ),
    [content],
  )
  const [collapse, setCollapse] = useState(isTextContentOverflowing)

  const { menuProps, openMenu, closeMenu } = useMenu()

  const {
    data: { data: userData } = {},
    error: errorUser,
  } = useGetUserSessionData({
    initialData: initialData?.userData,
    placeholderData: initialData?.userData,
  })

  const queryClient = useQueryClient()
  const mutationSendReaction = trpc.messages.chat.sendReaction.useMutation({
    onError: () => {
      enqueueSnackbar(t("message.reaction.addError.unknown"), {
        variant: "error",
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.messages.chat.get),
      })
    },
  })
  const mutationRemoveReaction = trpc.messages.chat.deleteReaction.useMutation({
    onError: () => {
      enqueueSnackbar(t("message.reaction.removeError.unknown"), {
        variant: "error",
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.messages.chat.get),
      })
    },
  })
  const mutationDeleteMessage = trpc.messages.chat.deleteMessage.useMutation({
    onError: () => {
      enqueueSnackbar(t("message.delete.error.unknown"), {
        variant: "error",
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.messages.chat.get),
      })
    },
  })

  const isAttachmentOnly = !subject && !content && !!attachments?.length
  const isAllowedToEdit = userData?.userId === creatorPerson?.id && !readonly

  const messageStatusIcon = useMemo(() => {
    switch (status) {
      case "failed":
        return (
          <Tooltip title={t("message.status.failed")}>
            <Icon
              size={0.75}
              path={mdiAlertCircleOutline}
              color={theme.palette.action.active}
            />
          </Tooltip>
        )
      case "sent":
        return (
          <Tooltip title={t("message.status.sent")}>
            <Icon
              size={0.75}
              path={mdiCheck}
              color={theme.palette.action.active}
            />
          </Tooltip>
        )
      case "pending":
        return (
          <Tooltip title={t("message.status.pending")}>
            <Icon
              size={0.75}
              path={mdiTimerSand}
              color={theme.palette.action.active}
            />
          </Tooltip>
        )
      default:
        return null
    }
  }, [status, theme.palette.action.active, t])

  const handleNewAiDraft = useCallback(
    (messageId: string, text = "") => {
      onReply?.(messageId, text)
    },
    [onReply],
  )

  const handleAddReactionConfirm = useCallback(
    (emoji: string) => {
      mutationSendReaction.mutate({
        payload: {
          chat_message_id: messageId,
          reaction: emoji,
        },
      })
    },
    [mutationSendReaction, messageId],
  )

  const handleToggleReaction = (emoji: string) => {
    // check if user already reacted with this emoji
    const matchedReaction = reactions?.find(
      (
        reaction: Pick<chat_message_reactions, "reaction" | "sender_person_id">,
      ) =>
        reaction.reaction === emoji &&
        reaction.sender_person_id === userData?.userId,
    )

    if (matchedReaction) {
      mutationRemoveReaction.mutate({
        reactionId: matchedReaction.id,
      })
    } else {
      mutationSendReaction.mutate({
        payload: {
          chat_message_id: messageId,
          reaction: emoji,
        },
      })
    }
  }

  const handleEditMessage = () => {
    closeMenu()
    onEdit?.(messageId)
  }

  const handleDeleteMessage = () => {
    closeMenu()
    mutationDeleteMessage.mutate({
      messageId,
    })
  }

  // TODO: add laoding state to chat message

  if (errorUser) throw errorUser
  return (
    <Stack
      ref={ref}
      className={`max-w-2xl  ${
        outgoing ? "items-end ml-auto" : "items-start mr-auto"
      }`}
      spacing={0.5}
      {...attributes}
    >
      <Stack
        direction={outgoing ? "row" : "row-reverse"}
        alignItems="center"
        spacing={1}
      >
        {typing && (
          <Typography variant="caption" color="text.disabled">
            {t("typing")}
          </Typography>
        )}
        {createdAt && (
          <Typography variant="caption" color="text.disabled">
            {dayjs(createdAt).format(getDayjsDateTimeFormat())}
          </Typography>
        )}
        {isCreatedBySystem ? (
          <SystemChip size="tiny" />
        ) : (
          <Stack
            direction={outgoing ? "row" : "row-reverse"}
            alignItems="center"
          >
            <PersonChip size="tiny" person={creatorPerson} />
            {!outgoing && creatorOrganization && creatorPerson && (
              <Icon
                path={mdiChevronRight}
                size={0.75}
                color={theme.palette.action.active}
              />
            )}
            {!outgoing && creatorOrganization && (
              <OrganizationChip
                size="tiny"
                organization={creatorOrganization}
              />
            )}
          </Stack>
        )}
      </Stack>
      {(content || subject) && (
        <Badge
          className="w-full"
          variant="dot"
          invisible={!unread}
          color="info"
        >
          <Stack
            data-testid={`chat-message-${unread ? "unread" : "read"}`}
            className="w-full rounded-lg items-end py-2 px-3 relative"
            sx={{
              bgcolor: outgoing
                ? alpha(theme.palette.primary.main, 0.08)
                : theme.palette.grey[200],
            }}
          >
            <Stack
              className={`absolute top-1 right-3 transition ease-in-out ${
                hovering
                  ? "opacity-100 translate-x-0"
                  : "opacity-0 translate-x-4"
              }`}
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {emailId && (
                <Tooltip title={t("message.viewEmail")}>
                  <IconButton
                    size="small"
                    onClick={() => openAside("email", emailId)}
                  >
                    <Icon path={mdiEye} size={1} />
                  </IconButton>
                </Tooltip>
              )}
              {emailId && caseId && (
                <AiMessageButton
                  data-testid="ai-generate"
                  iconButton
                  caseId={caseId}
                  replyToMessageId={messageId}
                  recipientParticipantId={recipientParticipantId}
                  onGenerate={(text) => handleNewAiDraft(messageId, text)}
                />
              )}
              {isAllowedToEdit && (
                <IconButton size="small" onClick={openMenu}>
                  <Icon path={mdiDotsVertical} size={1} />
                </IconButton>
              )}
            </Stack>
            {subject && (
              <Typography
                className="w-full align-right"
                variant="body2"
                pr={3}
                component="div"
                color="text.secondary"
              >
                {subject}
              </Typography>
            )}
            <Typography
              className="w-full align-right"
              variant="body2"
              pr={3}
              component="div"
              whiteSpace="pre-line"
            >
              {isTextContentOverflowing && (
                <Collapse in={!collapse} collapsedSize={68}>
                  <EmailPlainTextBody body={content} />
                </Collapse>
              )}
              {!isTextContentOverflowing && (
                <EmailPlainTextBody body={content} />
              )}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              {isTextContentOverflowing && (
                <Button
                  size="small"
                  color="info"
                  endIcon={
                    <Icon
                      path={collapse ? mdiChevronDown : mdiChevronUp}
                      size={1}
                    />
                  }
                  onClick={setCollapse.bind(null, !collapse)}
                >
                  {collapse ? t("message.readMore") : t("message.readLess")}
                </Button>
              )}
              {status && messageStatusIcon}
              <Typography variant="caption" color="text.disabled">
                {dayjs(createdAt).format(getDayjsTimeFormat())}
              </Typography>
            </Stack>
          </Stack>
        </Badge>
      )}
      {attachments && (
        <Badge
          data-testid="chat-message-attachment"
          variant="dot"
          invisible={!unread || !isAttachmentOnly}
          color="info"
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent={outgoing ? "flex-end" : "flex-start"}
            gap={1}
          >
            {attachments?.map((attachment) => (
              <MessageAttachment
                key={attachment.id}
                attachment={attachment}
                createdAt={
                  isAttachmentOnly && createdAt ? createdAt : undefined
                }
                toolbarSlot={
                  isAttachmentOnly && (
                    <IconButton size="small" onClick={openMenu}>
                      <Icon path={mdiDotsVertical} size={1} />
                    </IconButton>
                  )
                }
              />
            ))}
          </Stack>
        </Badge>
      )}
      {/**
       * TODO: ensure to set dense prop automatically (depending on globel theme preference)
       */}
      {!readonly && (
        <Menu
          id="edit-message-menu"
          {...menuProps}
          MenuListProps={{
            dense: true,
          }}
        >
          <MenuItem
            data-testid="edit-message-menu-option-edit-message"
            onClick={handleEditMessage}
          >
            <ListItemIcon>
              <Icon path={mdiPencil} size={0.75} />
            </ListItemIcon>
            <ListItemText>{t("message.edit.editMessage")}</ListItemText>
          </MenuItem>
          <MenuItem
            data-testid="edit-message-menu-option-delete-message"
            onClick={handleDeleteMessage}
          >
            <ListItemIcon>
              <Icon path={mdiDelete} size={0.75} />
            </ListItemIcon>
            <ListItemText>{t("message.edit.deleteMessage")}</ListItemText>
          </MenuItem>
        </Menu>
      )}
      {!readonly && (
        <Collapse in={(reactions && reactions?.length > 0) || hovering}>
          <ReactionCounter
            className={`transition ease-in-out ${
              (reactions && reactions?.length > 0) || hovering
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-4"
            }`}
            messageId={messageId}
            userId={userData?.userId}
            counters={reactions ?? []}
            onSelect={handleToggleReaction}
            onAdd={handleAddReactionConfirm}
            emojis={SUPPORTED_EMOJIS}
          />
        </Collapse>
      )}
    </Stack>
  )
}
