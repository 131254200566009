"use client"

import {
  Popper as MuiPopper,
  type PopperProps as MuiPopperProps,
} from "@mui/material"
import { forwardRef } from "react"

export const Popper = forwardRef<HTMLDivElement, MuiPopperProps>(
  (props, ref) => <MuiPopper {...props} ref={ref} />,
)
export type PopperProps = MuiPopperProps
Popper.displayName = "Popper"
