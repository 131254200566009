import { Typography } from "@/components/ui/data-display/Typography"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { Paper } from "@/components/ui/surfaces/Paper"
import { Fade } from "@/components/ui/utils/Fade"
import { Popper } from "@/components/ui/utils/Popper"
import type { PopperProps } from "@mui/material/Popper"

import { useKeydown } from "@/app/_hooks/useKeydown"
import CustomClickAwayListener from "@/components/ui/utils/CustomClickAwayListener"
import { useTheme } from "@mui/material/styles"
import { useRef } from "react"

export interface ReactionSelectPopperProps
  extends Omit<PopperProps, "onSelect"> {
  onSelect?: (emoji: string) => void
  onClose?: () => void
  emojis?: string[]
}

export default function ReactionSelectPopper({
  emojis = [],
  onSelect = () => {},
  onClose = () => {},
  ...popperProps
}: ReactionSelectPopperProps) {
  const theme = useTheme()
  const paperRef = useRef<HTMLDivElement>(null)
  useKeydown("Escape", onClose)

  return (
    <CustomClickAwayListener onClickAway={onClose} childRef={paperRef}>
      <Popper {...popperProps} sx={{ zIndex: theme.zIndex.drawer + 50 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              ref={paperRef}
              sx={{
                borderRadius: "24.5px",
                padding: "2px",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {emojis?.map((emoji: string) => (
                <IconButton
                  key={emoji}
                  data-testid={`message-reaction-emoji-option-${emoji}`}
                  onClick={onSelect.bind(null, emoji)}
                  sx={{
                    margin: "0.5px",
                  }}
                >
                  <Typography
                    lineHeight={1}
                    className="text-xl"
                    color="text.primary"
                  >
                    {emoji}
                  </Typography>
                </IconButton>
              ))}
            </Paper>
          </Fade>
        )}
      </Popper>
    </CustomClickAwayListener>
  )
}
