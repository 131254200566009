"use client"

import { Tooltip } from "@/components/ui/data-display/Tooltip"
import { Typography } from "@/components/ui/data-display/Typography"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import { type UseRewardParams, preset } from "@/utils/reward"
import { type HTMLAttributes, forwardRef, useMemo } from "react"
import { useReward } from "react-rewards"

export interface CounterObject {
  emoji: string
  by: string
}

export interface ReactionCounterGroupProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onSelect"> {
  messageId?: string
  active?: boolean
  onSelect?: (emoji: string) => void
  emoji: string
  count?: number
  names?: string[]
  disableReward?: boolean
}

function listOfNames(names: string[]) {
  return [names.slice(0, -1).join(", "), names.slice(-1)[0]].join(
    names.length < 2 ? "" : ", ",
  )
}

const ReactionCounterGroup = forwardRef<
  HTMLDivElement,
  ReactionCounterGroupProps
>(
  (
    {
      messageId,
      active = false,
      onSelect,
      emoji = "",
      count,
      names = [],
      disableReward = false,
      ...attributes
    }: ReactionCounterGroupProps,
    ref,
  ) => {
    const rewardPreset = useMemo(() => {
      const uuid = messageId ?? Math.random().toString(36).substring(7)
      const presetConfig = preset.incrementReaction(emoji)

      return {
        ...presetConfig,
        id: presetConfig.id + uuid,
        params: [
          presetConfig.id + uuid,
          ...presetConfig.params.slice(1),
        ] as UseRewardParams,
      }
    }, [emoji, messageId])
    const { reward } = useReward(...rewardPreset.params)

    const handleClick = () => {
      if (onSelect) {
        onSelect(emoji)
        if (!disableReward && !active) reward()
      }
    }

    const width = count ? "w-auto" : "w-5"
    const activeClasses = active
      ? "border-primary bg-blue-50"
      : "border-gray-200 bg-gray-100"

    return (
      <Box
        ref={ref}
        className={`rounded-md cursor-pointer h-5 ${width} pt-[1px] pr-[3px] pl-[4px] border border-solid hover:bg-blue-50 hover:border-primary ${activeClasses} transition ease-in-out`}
        onClick={handleClick}
        {...attributes}
      >
        <span className="h-0 w-0" id={rewardPreset.id} />
        <Tooltip title={listOfNames(names)}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="body1" color="text.primary" lineHeight={1}>
              {emoji}
            </Typography>
            {count && (
              <Typography
                variant="caption"
                color="text.secondary"
                lineHeight={1.5}
              >
                {count}
              </Typography>
            )}
          </Stack>
        </Tooltip>
      </Box>
    )
  },
)
ReactionCounterGroup.displayName = "ReactionCounterGroup"

export default ReactionCounterGroup
