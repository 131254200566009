import { Chip } from "@/components/ui/data-display/Chip"
import { Tooltip } from "@/components/ui/data-display/Tooltip"
import { Typography } from "@/components/ui/data-display/Typography"
import { Stack } from "@/components/ui/layout/Stack"
import { useTranslation } from "@/i18n"
import { mdiCreation } from "@mdi/js"
import Icon from "@mdi/react"
import { useTheme } from "@mui/material/styles"
import type { organizations } from "dcp-types"
import type { Selectable } from "kysely"
import type { HTMLAttributes } from "react"
import de from "./_messages/de.json"
interface SystemChipProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "color"> {
  organization?: Pick<
    Selectable<organizations>,
    "logo_ref" | "type" | "service_provider_type" | "name" | "registered"
  > | null
  size?: "tiny" | "small" | "medium"
}

const variant = "body2"

export default function SystemChip({
  organization,
  size = "small",
  ...attributes
}: SystemChipProps) {
  const { t } = useTranslation(de)
  const chipSize = size !== "tiny" ? size : "small"

  return (
    <Chip
      {...attributes}
      className={`${size === "tiny" ? "h-5" : ""} ${attributes.className}`}
      avatar={<Icon path={mdiCreation} size={0.75} />}
      label={
        <Stack direction={"row"} alignItems={"center"}>
          <Tooltip title={organization?.name}>
            <Typography
              overflow={"hidden"}
              textOverflow="ellipsis"
              fontWeight={500}
              variant={variant}
              color={"text.secondary"}
            >
              {t("label")}
            </Typography>
          </Tooltip>
        </Stack>
      }
      size={chipSize}
    />
  )
}
