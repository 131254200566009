"use client"

import type { FC } from "react"
import {
  CardContent as MuiCardContent,
  type CardContentProps,
} from "@mui/material"

export const CardContent: FC<CardContentProps> = (props) => (
  <MuiCardContent {...props} />
)
