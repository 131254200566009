/**
 * IMPORTANT: This file is shared between the server and the client.
 */

import type { ArrayElement } from "@/global"
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server"
import type { MessageRouter } from "."
import type { ChatRouter } from "./routers/chat"
import type { EmailRouter } from "./routers/emails"

// Constants for Pagination
export const DEFAULT_PAGINATION_LIMIT = {
  Notifications: 10,
  Messages: 10,
  // Add other constants for other request types here
}

export type TConversation = ArrayElement<
  NonNullable<inferRouterOutputs<MessageRouter>["list"]>["results"]
>

export type TSendChatMessage =
  inferRouterInputs<ChatRouter>["sendMessage"]["payload"]

export type TSendEmailMessage =
  inferRouterInputs<EmailRouter>["sendMessage"]["payload"]

export type TEditChatMessage =
  inferRouterInputs<ChatRouter>["editMessage"]["payload"]

export type TChatMessage = ArrayElement<
  NonNullable<inferRouterOutputs<ChatRouter>["get"]>["results"]
>

export type TEmailMessage = ArrayElement<
  NonNullable<inferRouterOutputs<EmailRouter>["get"]>["results"]
>

export type TChatMessageReaction = ArrayElement<TChatMessage["reactions"]>
